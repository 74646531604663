<template>
  <div>
    <el-dialog title="已选标" :visible.sync="dialogVisible" width="60%" append-to-body>
      <div class="bidInfo">
        <!-- <template v-for="(bidInfo, index) in choiceBids">
          <el-divider content-position="left">
            <span style="font-size: 16px;">{{ bidInfo.bidName }}</span>
          </el-divider>
          <el-descriptions direction="vertical" :column="6" border>
            <el-descriptions-item label="采购方式">
              {{ bidInfo.bidType | dict(dictData.bidType) }}
            </el-descriptions-item>
            <el-descriptions-item label="是否重点关注">
              {{ bidInfo.bidHostEscort == 'IS_FLAG' ? '是' : '否' }}
            </el-descriptions-item>
            <el-descriptions-item label="开标时间" :span="2">
              {{ bidInfo.bidOpeningTime | timeFormat }}
            </el-descriptions-item>
            <el-descriptions-item label="业主单位" :span="2">
              {{ bidInfo.bidOwnerUnit }}
            </el-descriptions-item>
            <el-descriptions-item label="代理\招标单位" :span="2">
              {{ bidInfo.bidAgency }}
            </el-descriptions-item>
            <el-descriptions-item label="中标金额(元)" :span="2">
              {{ bidInfo.bidWinningAmount | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="状态">
              {{ bidInfo.bidStatus | dict(dictData.bidStatus) }}
            </el-descriptions-item>
          </el-descriptions>
        </template> -->
        <el-table :data="choiceBids" border>
          <el-table-column align="center" type="index" label="序号"></el-table-column>
          <el-table-column align="center" prop="bidName" min-width="280" :show-overflow-tooltip="false"
            label="标的名称"></el-table-column>
          <el-table-column align="center" prop="bidType" width="100" :show-overflow-tooltip="false" label="采购方式">
            <template slot-scope="scope">
              {{ scope.row.bidType | dict(dictData.bidType) }}
            </template>
          </el-table-column>

          <el-table-column align="center" width="70" :show-overflow-tooltip="false" prop="bidHostEscort" label="是否重点关注">
            <template slot-scope="scope">
              {{ scope.row.bidHostEscort == 'IS_FLAG' ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="180" sortable prop="bidOpeningTime" label="开标时间">
            <template slot-scope="scope">
              {{ scope.row.bidOpeningTime | timeFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" :show-overflow-tooltip="false" min-width="220" prop="bidOwnerUnit"
            label="业主单位"></el-table-column>
          <el-table-column align="center" :show-overflow-tooltip="false" min-width="220" prop="bidOwnerUnit"
            label="代理\招标单位"></el-table-column>
          <el-table-column align="center" width="160" :show-overflow-tooltip="false" prop="bidWinningAmount"
            label="中标金额(元)">
            <template slot-scope="scope">
              {{ scope.row.bidWinningAmount | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="bidStatus" label="状态" width="160" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              {{ scope.row.bidStatus | dict(dictData.bidStatus) }}
            </template>
          </el-table-column>
        </el-table>
      </div>



      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  props: {
    bidInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    choiceBids: {
      type: Array,
      default: function () {
        return []
      }
    },
    dictData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return 0
      }
    },
    timeFormat(value, fmt) {
      if (value) {
        value = Number(value)
        return new Date(value).format('yyyy-MM-dd hh:mm:ss')
      } else {
        return ''
      }
    },
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.bidInfo {
  height: 60vh;
  overflow-y: auto;
}
</style>